<template>
  <external-projects></external-projects>
</template>

<script>
import ExternalProjects from "../components/ExternalProjects.vue";
import Enums from "../../../../plugins/enums";

export default {
  data() {
    return {
      enums: Enums,
    };
  },
  components: { ExternalProjects },
};
</script>
