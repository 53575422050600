export default [
  {
    text: "Actions",
    value: "actions",
    cellClass: "px-2",
    class: "px-2",
    align: "left",
    sortable: false,
    width: "64px",
    order: 0,
    hidable: true,
    hidden: false,
  },
  { text: "Name", value: "name", width: "250px", order: 1, hidable: false, hidden: false },
  { text: "Status", value: "status", width: "150px", order: 2, hidable: true, hidden: false },
];
