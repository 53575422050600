var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"height":"100%","display":"flex","flex-direction":"column"},attrs:{"fluid":""}},[_c('page-title',{attrs:{"title":"Projects<sup><i class='fas fa-circle-e fs-14px teal--text ml-1 mr-2'></i></sup>","badge":_vm.total}}),_c('v-row',{staticClass:"mt-0 mb-1",attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{staticClass:"d-flex align-start",staticStyle:{"gap":"0.5rem"},attrs:{"sm":"12","md":"3","cols":"12"}}),_c('v-col',{staticClass:"d-flex flex-row align-center justify-end flex-wrap pt-0",staticStyle:{"gap":"0.5rem"},attrs:{"md":"9","sm":"12","cols":"12"}},[_c('columns-visibility-control',{attrs:{"defaults":_vm.defaultHeaders,"withColumnsOrder":true,"storageKey":_vm.storageKey},model:{value:(_vm.headers),callback:function ($$v) {_vm.headers=$$v},expression:"headers"}}),_c('v-text-field',{ref:"mainSearch",staticClass:"table-search-field",style:({
          'max-width':
            _vm.mainSearchInFocus || (_vm.options.search != '' && _vm.options.search != null)
              ? '200px'
              : '110px',
        }),attrs:{"label":"Search","dense":"","solo":"","hide-details":"","clearable":"","prepend-inner-icon":"far fa-search","loading":_vm.loadingStates.table},on:{"focus":function($event){_vm.mainSearchInFocus = true},"blur":function($event){_vm.mainSearchInFocus = false}},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}}),_c('refresh',{attrs:{"loading":_vm.loadingStates.table},on:{"refresh":function($event){return _vm.getDataDebounced()}}})],1)],1),_c('v-data-table',{staticClass:"elevation-2 projects-table table-sticky-header-exclude-footer",staticStyle:{"overflow":"overlay","height":"100%","flex":"1 1 auto","display":"flex","flex-direction":"column"},attrs:{"dense":"","headers":_vm.tableHeader,"items":_vm.entities,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.options.itemsPerPage,"loading":_vm.loadingStates.table,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'far fa-arrow-to-left',
      lastIcon: 'far fa-arrow-to-right',
      prevIcon: 'far fa-angle-left',
      nextIcon: 'far fa-angle-right',
      itemsPerPageOptions: [15, 30, 50, 100],
    }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"blue","absolute":"","indeterminate":""}})]},proxy:true},{key:"header.jiraCode",fn:function(ref){
    var header = ref.header;
return [_c('i',{class:header.icon}),_vm._v(" "+_vm._s(header.text.toUpperCase())+" ")]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('h4',[_vm._v(_vm._s(item.fullName))])]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('project-status',{attrs:{"status":item.status,"small":"","show-parent":""}})]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","elevation":"0"},on:{"click":function($event){$event.stopPropagation();return _vm.update(item.id)}}},[_c('i',{staticClass:"fal fa-pen"})])]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"500","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true},{key:"loading",fn:function(){return [_c('video',{staticStyle:{"padding":"0.5rem"},attrs:{"width":"250","muted":"","loop":"","autoplay":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"/img/art/astronaut-loves-music-4980476-4153140.mp4","type":"video/mp4"}})]),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Searching the Cosmos...")])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }