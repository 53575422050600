import Api from "../../../Shared/services/api";
import ApiUploader from "../../../Shared/services/apiUploader";
import { buildParams } from "../../../../plugins/helpers";

const baseUrl = "ExternalProjects";
export default {
  typeHead(search, all) {
    const qParams = new URLSearchParams({ search, all });
    return Api().get(`${baseUrl}/typeHead?` + qParams.toString());
  },
  query(options) {
    const qParams = new URLSearchParams(options);
    return Api().get(baseUrl + "?" + qParams.toString());
  },
  add(entity) {
    return Api().post(baseUrl, entity);
  },
  get(id) {
    return Api().get(`${baseUrl}/${id}`);
  },
  getOverview(projectId) {
    return Api().get(`${baseUrl}/${projectId}/Overview`);
  },
  getSpacesLiteOverview(projectId) {
    return Api().get(`${baseUrl}/${projectId}/SpacesLiteOverview`);
  },
  getProjectVariables(projectId) {
    return Api().get(`${baseUrl}/${projectId}/Variables`);
  },
  getProjectItem(projectId, item) {
    return Api().get(`${baseUrl}/${projectId}/${item}`);
  },
  saveProjectItem(projectId, key, data) {
    return Api().post(`${baseUrl}/${projectId}/${key}`, data);
  },
  saveVariables(projectId, data) {
    return Api().post(`${baseUrl}/${projectId}/Variables`, data);
  },
  getSpaces(projectId, options) {
    const qParams = new URLSearchParams(options);
    return Api().get(`${baseUrl}/${projectId}/Spaces?` + qParams.toString());
  },
  getBuildings(projectId) {
    return Api().get(`${baseUrl}/${projectId}/Buildings`);
  },
  getBuildingsLevelsSpaces(projectId) {
    return Api().get(`${baseUrl}/${projectId}/BuildingsLevelsSpaces`);
  },
  getRecent(orderBy, count) {
    return Api().get(`${baseUrl}/Recent?Count=${count}&OrderBy=${orderBy}`);
  },
  update(entity) {
    if (entity.id) return Api().put(baseUrl + `/${entity.id}`, entity);
    else return this.add(entity);
  },
  updateAssignee(projectId, entity) {
    if (entity.id) return Api().put(baseUrl + `/${projectId}/Assignee`, entity);
    else return Api().post(baseUrl + `/${projectId}/Assignee`, entity);
  },
  uploadImage(imageData, config) {
    return ApiUploader().post(`${baseUrl}/Image`, imageData, config);
  },
  delete(id) {
    return Api().delete(baseUrl + `/${id}`);
  },
  restore(id) {
    return Api().put(baseUrl + `/${id}/restore`);
  },
  deleteAssignee(id) {
    return Api().delete(`${baseUrl}/${id}/Assignees`);
  },
  checkIfProjectNameExist(name) {
    return Api().get(`${baseUrl}/${name}/Exist`);
  },
  validateProjectNumber(number) {
    return Api().get(`${baseUrl}/ValidateProjectNumber/${number}`);
  },
  updateProjectNumber(projectId, number) {
    return Api().put(`${baseUrl}/${projectId}/UpdateProjectNumber/${number}`);
  },
  cloneProject({ projectId, ...data }) {
    return Api().post(`${baseUrl}/${projectId}/Clone`, data);
  },
  stagesCount() {
    return Api().get(`${baseUrl}/StagesCount`);
  },
  systemOverviewStats() {
    return Api().get(`${baseUrl}/SystemOverviewStats`);
  },
  export(options) {
    const qParams = new URLSearchParams(options);
    const url = `${baseUrl}/Export?${qParams.toString()}`;
    return Api().get(url);
  },
  exportWithDate(options) {
    const qParams = buildParams(options);
    const url = `${baseUrl}/ExportByDate?${qParams.toString()}`;
    return Api().get(url);
  },
  exportSpacesDocuments(projectId) {
    return Api().get(`${baseUrl}/${projectId}/SpacesDocuments/Export`, {
      responseType: "blob",
    });
  },
  importSpacesDocuments(projectId, fileData, config) {
    return Api().post(`${baseUrl}/${projectId}/SpacesDocuments/Import`, fileData, config);
  },
  getCategoriesEquipmentCount(projectId) {
    return Api().get(`${baseUrl}/${projectId}/CategoriesEquipmentCount`);
  },
  getAccessList(projectId) {
    return Api().get(`${baseUrl}/${projectId}/AccessList`);
  },
  saveAccessList(projectId, data) {
    return Api().put(`${baseUrl}/${projectId}/AccessList`, data);
  },
};
